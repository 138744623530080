<template>
    <div class="d-flex align-items-center justify-content-center pt-3">
        <div class="row-holder"  v-if="activeProvider.Label">
            <b-row class="">
               <b-col cols="12" class="text-center">
                   <font-awesome-icon
                       :icon="['fab', 'stripe']"
                       class="sk-integration-logo"
                       size="3x"/>
               </b-col>
           </b-row>
            <b-row >
                <b-col cols="12" class="text-center">
                    <h2 class="mb-2">
                        {{i18nT(`Connecting your ${activeProvider.Label} account`)}}<br/>{{i18nT(`Please wait...`)}}
                    </h2>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="text-center">
                    <b-spinner
                        v-if="loading"
                        :variant="`primary`"
                        class=""/>
                </b-col>
            </b-row>
        </div>
        <div class="row-holder"  v-else="activeProvider.Label">
            <b-row >
                <b-col cols="12" class="text-center">
                    <h2 class="mb-2">
                        {{i18nT(`Unable to detect payment provider`)}}<br/>{{i18nT(`Please try again later`)}}
                    </h2>
                </b-col>
            </b-row>
        </div>
    </div>

</template>

<script>
import {
    BRow,
    BCol,
    BSpinner,
    //BNav,
    //BNavItem
} from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BSpinner,
        BRow,
        BCol,
    },
    data() {
        return {
            loading: true,
            providers: [],
            activeProvider: {}
        }
    },
    methods: {},
    mounted() {
        // this.$store.dispatch('settings/connectStripe', this.$route.query.code)
        //     .then(() => {
        //         this.loading = false
        //         this.$router.push({name: 'settings'})
        //     })
    },
    beforeMount() {},
    created() {
        this.$http.get(`settings/paymentProviders`).then(({data}) => {
            this.providers = data.data;

            let providerParams = this.$route.params.provider;
            let providerName = providerParams.split('&')[0];

            this.activeProvider = this.providers.find(provider => provider.Short === providerName);

            console.log('providerObj', this.activeProvider);
            console.log("Query params data: ", this.$route.query);
            if(this.activeProvider) {

                if(this.$route.query.code) {

                    let formData = new FormData;
                    formData.append('code', this.$route.query.code);
                    formData.append('payment_provider_id', this.activeProvider.Id);

                    this.$http.post(`stripeconnect/oauth`, formData).then((res) => {
                        this.loading = false
                        this.$toast({
                            component: ToastificationContent,
                            position: "top-right",
                            props: {
                                title: this.successMessage(res),
                                icon: "InfoIcon",
                                variant: "success",
                            },
                        });
                        this.$router.push({path: '/ac/settings/payment-providers'})
                    }).catch((err) => {
                        this.$toast({
                            component: ToastificationContent,
                            position: "top-right",
                            props: {
                                title: this.errorMessage(err),
                                icon: "InfoIcon",
                                variant: "danger",
                            },
                        });
                    });

                }
                // this.$store.dispatch('settings/connectStripe', this.$route.query.code)
                //     .then(() => {
                //         this.loading = false
                //         this.$router.push({name: 'settings'})
                //     })
            }


        })
    },
}
</script>

<style>
.suggestion-box {
    display: none;
}

.sk-integration-logo {
    color: var(--primary);
    font-size: 94px;
}
</style>
